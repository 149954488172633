h1, h2, h3 {
    color: #0A2F51;
}

h1{
    font-family: 'Parisienne', cursive;
    font-size: 3em;
}



h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 700; /* Bold weight */
    font-size: 2em;
}

p {
    font-family: 'Quicksand', sans-serif;
}

h3 {
    font-family: 'Parisienne', cursive;
    font-size: 1.5em;
}